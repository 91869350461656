<script>

import {mapActions, mapGetters} from "vuex";

export default {
  data: () => ({}),
  computed: {
    ...mapGetters(['getSound', 'getBgMusicTime'])
  },
  beforeDestroy() {
    document.body.style.overflow = "visible"
    window.removeEventListener("resize", {})
  },
  mounted() {
    document.title = "Нестандартна медицина в масовій культурі";
    document.body.style.overflow = "hidden"
    let vh = window.innerHeight * 0.01;

    document.documentElement.style.setProperty('--vh2', `${vh}px`);

    window.addEventListener("resize", () => {

      let vh = window.innerHeight * 0.01;

      document.documentElement.style.setProperty('--vh2', `${vh}px`);

    });

    this.$nextTick(() => {
      document.getElementById('bg-audio').currentTime = this.getBgMusicTime

      if (!this.getSound) {
        document.getElementById('bg-audio').pause()
      }
    })
  },
  methods: {
    ...mapActions(["doSound", 'doBgMusicTime']),
    nextStage() {
      if (this.getSound) {
        document.getElementById('click-audio').play()
      }
      setTimeout(() => {
        this.$router.push({name: 'quizHub'})
        this.doBgMusicTime(document.getElementById('bg-audio').currentTime)
      }, 150)

    },
    mute() {
      let audio = document.getElementById('bg-audio');
      if (!this.getSound) {
        audio.play()
        this.doSound(true)
      } else {
        audio.pause()
        this.doSound(false)
      }
    },
  }
}
</script>

<template>
  <section class="hub">
    <audio id="bg-audio" src="@/assets/audio/bg-music.mp3" loop autoplay></audio>
    <audio id="click-audio" src="@/assets/audio/btn-click.mp3"></audio>
    <div class="content stage2">
      <div class="sound-btn" @click="mute()">
        <p class="sound-btn__text">звук</p>
        <div class="sound-btn__icon">
          <svg v-if="getSound" width="16" height="16" viewBox="0 0 16 16" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12.4091 1.38477H10.2771L7.11082 4.14247C6.7467 4.4596 6.28014 4.6343 5.79727 4.6343H4.01563C2.91106 4.6343 2.01562 5.52973 2.01562 6.6343V9.36547C2.01562 10.47 2.91106 11.3655 4.01563 11.3655H5.79727C6.28014 11.3655 6.7467 11.5402 7.11082 11.8573L10.2771 14.615H12.4091V1.38477Z"
              fill="black"/>
          </svg>

          <svg v-else width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.9832 3.625L2.99316 14.615" stroke="black" stroke-width="1.4" stroke-linecap="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M12.4091 3.078V1.38477H10.2771L7.11082 4.14247C6.7467 4.4596 6.28014 4.6343 5.79727 4.6343H4.01563C2.91106 4.6343 2.01562 5.52973 2.01562 6.6343V9.36547C2.01562 10.47 2.91106 11.3655 4.01563 11.3655H4.12165L12.4091 3.078ZM12.4091 7.32064L7.51791 12.2118L10.2771 14.615H12.4091V7.32064Z"
                  fill="black"/>
          </svg>

        </div>
      </div>
      <div class="img">
        <img src="@/assets/img/quiz-game/bg-main-tm.jpg" alt="">

      </div>
      <div class="info">
        <div class="corner corner1">
          <svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17 1H3C1.89543 1 1 1.89543 1 3V17" stroke="#94FFDA" stroke-width="2"/>
          </svg>
        </div>

        <div class="corner corner2">
          <svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.55184e-08 1H14C15.1046 1 16 1.89543 16 3V17" stroke="#94FFDA" stroke-width="2"/>
          </svg>
        </div>

        <div class="corner corner3">
          <svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17 16H3C1.89543 16 1 15.1046 1 14V4.55184e-08" stroke="#94FFDA" stroke-width="2"/>
          </svg>
        </div>

        <div class="corner corner4">
          <svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.55184e-08 16H14C15.1046 16 16 15.1046 16 14V4.55184e-08" stroke="#94FFDA" stroke-width="2"/>
          </svg>
        </div>

        <div class="info__content">
          <div class="info__title">
            <p>Як грати</p>
          </div>
          <div class="info__text">
            <p>Обирайте один з цікавих фактів
              і дізнавайтеся більше!</p>
          </div>
          <div class="info__btn" @click="nextStage()">
            <p>Почати гру</p>
          </div>
        </div>
      </div>
    </div>
  </section>

</template>

<style scoped lang="scss">
#bg-audio {
  position: fixed;
  left: -100%;
  top: -100%;
}

#playButton, #click-audio {
  position: fixed;
  left: -100%;
  top: -100%;
}

.hub {
  background: #06243E;
  padding: 10px;
  max-height: calc((var(--vh2, 1vh) * 100));
  min-height: calc((var(--vh2, 1vh) * 100));
  height: 100vh;
  height: calc((var(--vh2, 1vh) * 100));
  width: 100%;
  position: relative;
  font-family: 'IBM Plex Mono', monospace;
  overflow: hidden;
}

.sound-btn {
  position: absolute;
  z-index: 20;
  right: 22px;
  top: 8px;
  display: flex;
  align-items: center;

  @media screen and (min-width: 1023px) {

    &:hover {
      cursor: pointer;

      .sound-btn__icon {
        background: var(--green-200, #94FFDA);
      }
    }
  }
  @media screen and (max-width: 600px) {
    top: 0;
    right: 4px;
  }

  &__text {
    color: #FFF;
    text-align: center;

    font-size: 14px;
    font-weight: 700;
    line-height: 120% !important;
    letter-spacing: -0.28px;
    text-transform: uppercase;
    margin-right: 16px;
  }

  &__icon {
    transition: all .2s ease-in;
    border: 1px solid var(--green-500, #32AC82);
    background: var(--green-300, #3CEAAE);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
  }
}

.content {
  position: relative;
  background-size: contain;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .corner {
    position: absolute;

    svg {
      width: 17px;
      height: auto;
      display: block;
    }

    &.corner1 {
      left: -3px;
      top: -3px;
    }

    &.corner2 {
      right: -3px;
      top: -3px;
    }

    &.corner3 {
      left: -3px;
      bottom: -3px;
    }

    &.corner4 {
      right: -3px;
      bottom: -3px;
    }
  }

  .img {
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .animation2 {
      position: absolute;
      overflow: hidden;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: auto;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img {
      width: auto;
      height: 100%;
      //object-fit: cover;
      display: block;
      @media screen and (max-height: 920px) and (min-width: 1240px){
        max-width: 1420px;
        margin: 0 auto;

        width: 100%;
        height: auto;
      }
    }
  }

  .info {
    position: relative;
    z-index: 2;
    border: 1px solid rgba(38, 158, 117, 0.20);
    background: var(--grey-t-30, rgba(66, 80, 115, 0.30));
    max-width: calc(668px + 32px);
    width: 100%;

    padding: 16px;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 60px;


    &__content {
      border: 1px solid var(--green-500, #32AC82);
      background: rgba(9, 35, 57, 0.60);
    }

    &__title {
      background: var(--green-300, #3CEAAE);
      padding: 16px 0;

      p {
        color: var(--bg, #06243E);
        text-align: center;
        line-height: 40px !important;
        font-size: 50px;
        font-weight: 400;
        text-transform: uppercase;
      }
    }

    &__text {
      padding: 32px 60px;

      p {
        color: var(--White, #FFF);
        text-align: center;

        font-size: 22px;
        font-weight: 400;
        line-height: 140% !important;
      }
    }

    &__btn {

      @media screen and (min-width: 1023px) {
        &:hover {

          p {
            background: var(--green-300, #3CEAAE);
            color: #06243E;
          }
        }
      }

      padding: 0 16px;
      margin: 40px auto 24px auto;
      display: flex;
      justify-content: center;


      &:active {

        p {
          background: var(--green-300, #3CEAAE);
          color: #06243E;
        }
      }

      p {
        max-width: 400px;
        width: 100%;
        display: flex;
        justify-content: center;
        cursor: pointer;

        border-radius: 32px;
        border: 1px solid var(--green-300, #3CEAAE);
        background: var(--grey-t-80, rgba(18, 23, 34, 0.80));

        transition: color .2s ease, background .2s ease;
        padding: 21px 16px;
        color: var(--green-300, #3CEAAE);
        text-align: center;

        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
      }
    }
  }

  .back_btn {
    position: absolute;
    z-index: 2;
    right: 30px;
    top: 10px;
    cursor: pointer;

    p {
      color: #FFF;
      text-align: center;
      font-size: 16px;
      font-weight: 700;
      line-height: 160% !important;
      letter-spacing: -0.32px;
      text-transform: uppercase;
    }
  }

  .games {
    margin-left: 20px;
    margin-right: 20px;
    position: relative;
    z-index: 2;
    border: 1px solid rgba(38, 158, 117, 0.20);
    background: var(--grey-t-30, rgba(66, 80, 115, 0.30));
    max-width: calc(1188px + 32px);
    width: 100%;

    padding: 16px;
    max-height: calc(100% - 80px);
    height: 100%;


    &__content {
      background: rgba(18, 23, 34, 0.80);
      height: 100%;
    }

    &__title {
      padding: 24px 16px;

      p {
        color: var(--green-200, #94FFDA);
        text-align: center;
        font-size: 50px;
        font-weight: 400;
        line-height: 80% !important;
        text-transform: uppercase;

        br {
          display: none;
        }
      }
    }

    &__items {

      border: 1px solid var(--green-500, #32AC82);
      padding: 16px 8px;
      max-height: calc(100% - 88px);
      height: 100%;

      .scroll {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;

        grid-template-rows: 1fr 1fr 1fr 1fr 1fr;

        grid-gap: 4px;
        overflow-y: auto;
        overflow-x: hidden;
        padding-left: 8px;
        padding-right: 8px;

        height: 100%;

        &::-webkit-scrollbar {
          width: 4px;
        }

        &::-webkit-scrollbar-track {
          background: rgba(241, 241, 241, 0.25);
          border-radius: 3px;
        }

        &::-webkit-scrollbar-thumb {
          background: var(--green-500, #32AC82);
          border-radius: 3px;
        }
      }
    }

    &__item {
      user-select: none;
      position: relative;
      display: flex;
      padding: 16px;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      flex: 1 0 0;
      align-self: stretch;

      background: var(--green-300, #3CEAAE);
      border: 1px solid #3CEAAE;

      transition: background-color .2s ease, border .2s ease;

      &.disable {
        opacity: .3;
      }

      &:not(.disable) {

        @media screen and (min-width: 1023px) {
          &:hover {
            border: 1px solid var(--White, #FFF);
            background-color: var(--green-200, #94FFDA);
            cursor: pointer;

            .arrow {
              opacity: 1;
            }
          }
        }

        &:active {
          border: 1px solid var(--White, #FFF);
          background-color: var(--green-200, #94FFDA);

          .arrow {
            opacity: 1;
          }
        }
      }


      .arrow {
        position: absolute;
        right: 16px;
        top: 16px;
        opacity: 0;
        transition: opacity .2s ease;
      }

      .num {
        margin-bottom: 30px;

        color: var(--bg, #06243E);
        font-size: 12px;
        font-weight: 700;
        line-height: 10px !important;
      }

      .name {
        color: var(--bg, #06243E);
        font-size: 18px;
        font-weight: 700;
        line-height: 110% !important;
      }
    }
  }

  &.stage1 {
  }

  &.stage2 {
  }

  &.stage3 {
  }
}

@media screen and (max-width: 1023px) {
  .content {


    .img {

      img {

      }
    }

    .info {


      .corner {

        svg {

        }

        &.corner1 {

        }

        &.corner2 {

        }

        &.corner3 {

        }

        &.corner4 {

        }
      }

      &__content {

      }

      &__title {

        p {

        }
      }

      &__text {

        p {

        }
      }

      &__btn {

        p {
        }
      }
    }

    .back_btn {
      display: none;

      p {

      }
    }

    .games {
      margin-top: 80px;

      max-height: calc(100% - 80px);

      .corner {

        svg {

        }

        &.corner1 {

        }

        &.corner2 {

        }

        &.corner3 {

        }

        &.corner4 {

        }
      }

      &__content {

      }

      &__title {
        p {

        }
      }

      &__items {

        .scroll {
          grid-template-columns: 1fr 1fr;
          grid-template-rows: initial;
        }

        &::-webkit-scrollbar {
        }

        &::-webkit-scrollbar-track {
        }

        &::-webkit-scrollbar-thumb {
        }
      }

      &__item {
        .num {
        }

        .name {
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .hub {
    padding: 8px;
  }
  .content {

    .img {

      img {

      }
    }

    .info {

      margin-left: 8px;
      margin-right: 8px;
      padding: 8px;

      .corner {

        svg {

        }

        &.corner1 {

        }

        &.corner2 {

        }

        &.corner3 {

        }

        &.corner4 {

        }
      }

      &__content {

      }

      &__title {
        padding: 8px;

        p {
          font-size: 32px;

        }
      }

      &__text {

        padding: 16px;

        p {
          font-size: 15px;

        }
      }

      &__btn {

        margin-top: 24px;
        margin-bottom: 16px;
        max-width: 280px;

        @media screen and (min-width: 1023px) {
          &:hover {

            p {
            }
          }
        }

        &:active {

          p {
          }
        }

        p {
          font-size: 16px;

          padding: 17px 8px;
        }
      }
    }

    .back_btn {
      display: none;

      p {

      }
    }

    .games {

      margin-left: 8px;
      margin-right: 8px;
      padding: 8px;
      margin-top: 64px;
      max-height: calc(100% - 64px - 16px);

      .corner {

        svg {

        }

        &.corner1 {

        }

        &.corner2 {

        }

        &.corner3 {

        }

        &.corner4 {

        }
      }

      &__content {

      }

      &__title {
        p {
          font-size: 32px;
          line-height: 100% !important;

          br {
            display: block;
          }
        }
      }

      &__items {
        padding: 8px;
        max-height: calc(100% - 112px);

        .scroll {


          grid-template-columns: 1fr 1fr;
        }

        &::-webkit-scrollbar {
        }

        &::-webkit-scrollbar-track {
        }

        &::-webkit-scrollbar-thumb {
        }
      }

      &__item {
        padding: 8px;

        .num {
          margin-bottom: 20px;
        }

        .name {
          font-size: 14px;
        }
      }
    }
  }
}
</style>